<template>
  <div class="bok_con">
    <div class="bok_hea">
      <back />
      <p>我的积分</p>
      <div v-if="userInfo" class="scr_top">{{ userInfo.score }}</div>
      <div class="scr_btm">可用积分</div>
    </div>
    <div>
      <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh" id="listBox">
        <van-list
          style="height: calc(100vh - 150px); overflow-y: auto"
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="暂无更多数据"
          @load="onLoad"
        >
          <div class="sco_itm" v-for="item in scoreList" :key="item.id">
            <div class="itm_lft">
              <span>{{ item.scoreName }}</span>
              <span>{{ item.createdTime }}</span>
            </div>
            <span class="itm_rgt">{{ item.score }}</span>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import PersonApi from "@/api/person";
import authApi from "@/api/auth";
import back from "../globalcomponents/back.vue";

export default {
  components: { back },
  data() {
    return {
      page: 1,
      size: 18,
      scoreList: [],
      loading: false,
      finished: false,
      refreshing: false,
      userInfo: null,
    };
  },
  mounted() {
    this.queryInfo();
  },
  methods: {
    // 查询个人信息
    queryInfo() {
      authApi
        .memberInfomatin()
        .then((res) => {
          if (res.success) {
            console.log('积分', res)
            this.userInfo = res.data;
            this.queryScoreList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询积分列表
    queryScoreList() {
      const params = {
        page: this.page,
        size: this.size,
        memberId: JSON.parse(window.localStorage.personalInformation).id,
      };
      PersonApi.getScore(params)
        .then((res) => {
          this.loading = false;
          this.refreshing = false;
          let arr = res.data.items;
          if (this.page === 1) {
            this.scoreList = [];
          }
          if (arr) {
            this.scoreList = this.scoreList.concat(arr);
          }
          if (!arr || arr.length < this.size) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 上拉加载
    onLoad() {
      this.page += 1;
      this.queryScoreList();
    },

    // 下拉刷新
    onRefresh() {
      this.page = 1;
      this.queryInfo();
    },
  },
};
</script>

<style lang="less" scoped>
.bok_con {
  position: relative;
  .bok_hea {
    height: 150px;
    background: url("../../assets/financialBackground.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .scr_top {
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translate(-50%);
      font-weight: bold;
      font-size: 20px;
      color: #fff;
    }
    .scr_btm {
      position: absolute;
      top: 110px;
      left: 50%;
      transform: translate(-50%);
      color: #fff;
      font-size: 15px;
    }
    img {
      width: 25px;
      position: absolute;
      top: 20px;
      left: 7px;
    }
    p {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%);
      color: #fff;
      font-size: 17px;
    }
  }
  .sco_itm {
    padding: 0 13px;
    border-bottom: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .itm_lft {
      display: flex;
      flex-direction: column;
      span {
        padding: 5px 0;
        &:nth-child(2) {
          color: #969799;
        }
      }
    }
    .itm_rgt {
      color: green;
    }
  }
}
</style>
